<script>
import MenuBar from './components/MenuBar.vue'

import CreateEditReadForm from '../components/CreateEditReadForm.vue'

export default {
  components: {
    CreateEditReadForm,

    MenuBar
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<template>
  <b-container fluid="lg">
    <!-- page title / menu bar -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="เพิ่มลูกค้าลูกหนี้"></page-title>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar></menu-bar>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <create-edit-read-form
          :modeProp="'CREATE'"
          class="mb-3"
        ></create-edit-read-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<style></style>
